import React, { Component } from "react"

type OneUpProps = {
    theme: String,
};
type OneUpState = {};

export default class OneUp extends Component<OneUpProps, OneUpState> {
    render() {

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="144"
                height="144"
                version="1.1"
                viewBox="0 0 38.1 38.1"
            >
                <g fill={this.props.theme == 'light' ? 'black' : 'white'}>
                    <g display="inline" transform="matrix(.26458 0 0 .26458 -.112 4.993)">
                        <path d="M5.68 111.67l6.87-6.03c.18-.15.27-.29.27-.43 0-.18-.15-.28-.46-.28-.13 0-.22.01-.27.02-.58.07-1.02.1-1.29.1-.58 0-.88-.29-.88-.88 0-.55.35-.83 1.05-.83.37 0 .71.02 1 .05l1.29.15c.57.07 1 .1 1.29.1.58 0 1.31-.07 2.17-.2.44-.07.76-.1.97-.1.62 0 .93.28.93.85s-.28.85-.85.85h-.88c-.62 0-1.17.21-1.66.63l-4.6 3.95c-.19.17-.29.32-.29.45 0 .08.08.23.24.42l.37.58 3.9 5.45c1.04 1.45 1.82 2.18 2.34 2.18.03 0 .08-.01.15-.03h.12c.44-.05.71-.08.8-.08.58 0 .88.29.88.85 0 .57-.32.85-.95.85l-1.22-.15c-.6-.07-1.28-.1-2.05-.1-.88 0-1.79.05-2.75.15-.63.07-.99.1-1.07.1-.6 0-.9-.27-.9-.82 0-.59.32-.89.95-.89.18 0 .43.01.76.04.63.06 1 .08 1.1.08.39 0 .58-.08.58-.25 0-.12-.07-.28-.22-.48l-4.53-6.35c-.1-.13-.19-.2-.27-.2-.06 0-.17.07-.32.2l-2.56 2.31c-.02.3-.02.64-.02 1.03 0 1.46.01 2.24.02 2.34.13.95.54 1.43 1.22 1.43.1 0 .28-.02.54-.08.26-.05.48-.08.66-.08.21 0 .39.08.54.24.15.16.22.36.22.59 0 .59-.36.88-1.07.88h-.17a.555.555 0 00-.17-.03l-1.36-.2c-.34-.05-.87-.08-1.58-.08-.52 0-.95.03-1.29.08l-1.34.2c-.03 0-.08.01-.15.03h-.15c-.73 0-1.1-.3-1.1-.89 0-.54.25-.82.75-.82.15 0 .37.03.66.09.2.04.37.06.51.06.81 0 1.22-.63 1.22-1.88 0 .03.01-.55.02-1.76V108.52l-.02-1.76c0-1.25-.4-1.88-1.21-1.88-.16 0-.33.02-.51.06-.29.04-.51.06-.65.06-.52 0-.77-.28-.77-.83 0-.59.36-.88 1.09-.88.08 0 .19.01.32.03l1.17.16c.52.07.96.11 1.31.11.44 0 1.09-.05 1.97-.15.87-.1 1.38-.15 1.51-.15.68 0 1.02.29 1.02.88 0 .23-.07.43-.22.59-.15.16-.32.24-.53.24-.16 0-.38-.03-.65-.08-.11-.03-.28-.05-.51-.05-.56 0-.92.21-1.06.62-.15.41-.21 1.41-.19 3v3.18z"></path>
                        <path d="M33.1 112.82c0 .64-.04 1.03-.12 1.17-.08.14-.32.21-.71.21h-7.75c-.21 0-.35.03-.41.09s-.1.16-.1.29c0 1.26.37 2.3 1.1 3.14.73.84 1.64 1.26 2.73 1.26.71 0 1.37-.2 1.96-.6.59-.4.98-.91 1.16-1.53l.22-.73c.11-.4.38-.6.8-.6.6 0 .9.32.9.96 0 1.09-.54 2.05-1.61 2.9-1.07.84-2.29 1.27-3.65 1.27-1.71 0-3.06-.59-4.07-1.77-1.01-1.18-1.51-2.77-1.51-4.76 0-2.04.54-3.7 1.61-4.97 1.07-1.27 2.47-1.91 4.19-1.91 1.53 0 2.79.53 3.78 1.58.99 1.04 1.48 2.38 1.48 4zm-8.84-.65c0 .24.18.35.54.35h5.82c.34 0 .51-.12.51-.35 0-.89-.33-1.66-.97-2.31-.65-.65-1.41-.98-2.29-.98-.91 0-1.74.34-2.48 1.02-.76.68-1.13 1.43-1.13 2.27z"></path>
                        <path d="M41.19 110.06l2.8 8.49 2.97-8.36c.08-.23.12-.42.12-.55 0-.28-.19-.43-.56-.43-.19 0-.35.02-.46.05-.26.03-.43.05-.51.05-.54 0-.8-.27-.8-.8 0-.57.35-.85 1.05-.85.1 0 .26.02.49.05l.88.13c.23.03.47.05.73.05.28 0 .68-.04 1.22-.11.54-.08.94-.11 1.22-.11.7 0 1.05.28 1.05.84 0 .54-.27.82-.8.82-.16 0-.28-.02-.37-.05a2.44 2.44 0 00-.44-.08c-.31 0-.63.43-.95 1.28L45.5 119c-.73 1.89-1.42 3.26-2.07 4.12-1.1 1.46-2.48 2.18-4.14 2.18-.99 0-1.82-.26-2.5-.78-.67-.52-1.01-1.15-1.01-1.88 0-.52.15-.94.44-1.27.29-.33.67-.49 1.15-.49.37 0 .68.11.93.34.24.23.37.51.37.84 0 .54-.24.88-.71 1.03.24.37.71.55 1.39.55.86 0 1.64-.33 2.33-.99.69-.66 1.04-1.4 1.04-2.22 0-.42-.22-1.2-.66-2.33l-2.68-6.98-.29-.8c-.28-.75-.59-1.13-.95-1.13-.1 0-.27.02-.52.06-.25.04-.42.06-.5.06-.57 0-.85-.27-.85-.8 0-.57.37-.85 1.12-.85.24 0 .43.01.56.03l.97.15h.19c.11.02.21.03.29.03.58 0 1.21-.04 1.88-.12.5-.06.84-.09 1-.09.68 0 1.02.28 1.02.83 0 .55-.31.83-.93.83-.1 0-.25-.02-.46-.05a4.07 4.07 0 00-.46-.05c-.23 0-.34.12-.34.35-.02.12.02.29.08.49z"></path>
                        <path d="M57.61 104.21a52.06 52.06 0 00-.24 4.93c.52-.7 1.05-1.2 1.59-1.48s1.21-.43 2.03-.43c1.63 0 2.92.59 3.88 1.76s1.44 2.73 1.44 4.67c0 2.13-.51 3.82-1.52 5.08-1.02 1.26-2.38 1.9-4.1 1.9-1.67 0-2.88-.64-3.63-1.93.06.49.1.75.1.8 0 .6-.28.9-.85.9s-.85-.42-.85-1.26v-.53-1.23l.12-1.93v-9.25c-.02-.62-.08-1.04-.18-1.26-.11-.22-.29-.33-.55-.33-.05 0-.26.03-.63.1-.18.03-.33.05-.46.05-.55 0-.83-.27-.83-.8 0-.55.32-.83.97-.83.19 0 .36.01.49.03l.58.08c.26.03.47.05.63.05.18 0 .43-.03.76-.08.32-.05.57-.08.73-.08.36 0 .54.23.54.7v.37zm-.07 7.26c-.11.54-.17 1.29-.17 2.26 0 1.66.13 2.8.39 3.42.51 1.22 1.47 1.83 2.89 1.83 1.13 0 2.02-.46 2.69-1.37.66-.91.99-2.14.99-3.68 0-1.57-.31-2.81-.94-3.7-.63-.9-1.49-1.34-2.59-1.34-.77 0-1.46.25-2.08.74-.63.48-1.02 1.1-1.18 1.84z"></path>
                        <path d="M76.2 120.63c-1.71 0-3.07-.61-4.09-1.82s-1.53-2.84-1.53-4.88.52-3.67 1.55-4.88 2.42-1.82 4.15-1.82c1.77 0 3.17.59 4.19 1.78 1.02 1.19 1.54 2.8 1.54 4.85 0 2.04-.53 3.68-1.58 4.92-1.08 1.23-2.48 1.85-4.23 1.85zm.12-11.75c-1.14 0-2.03.41-2.68 1.23-.65.82-1.01 1.98-1.07 3.49v.33c0 1.57.32 2.81.96 3.7.64.9 1.54 1.34 2.69 1.34 1.19 0 2.12-.45 2.79-1.34.67-.9 1.01-2.14 1.01-3.73 0-3.35-1.23-5.02-3.7-5.02z"></path>
                        <path d="M96.8 120.53c-1.33 0-2.15-.59-2.46-1.78-.83 1.25-2.13 1.88-3.9 1.88-1.32 0-2.37-.33-3.15-1-.79-.67-1.18-1.57-1.18-2.69 0-1.39.58-2.41 1.73-3.06.44-.25.82-.41 1.13-.49.32-.08.95-.17 1.91-.29 1.36-.15 2.25-.36 2.67-.63.42-.27.62-.76.62-1.48 0-.69-.23-1.23-.69-1.62-.46-.39-1.11-.59-1.94-.59-.73 0-1.41.21-2.05.63-.24.17-.37.34-.37.53 0 .07.07.15.22.25.23.17.34.45.34.85 0 .33-.12.61-.35.83-.23.22-.53.33-.89.33-.42 0-.78-.15-1.06-.45-.28-.3-.43-.67-.43-1.1 0-.94.45-1.74 1.35-2.41.9-.67 1.99-1 3.28-1 2.96 0 4.43 1.46 4.43 4.38v5.06c0 .8.02 1.28.05 1.43.1.58.37.88.83.88.57 0 .85-.53.85-1.6v-1.22c0-.53.25-.8.74-.8.53 0 .79.4.79 1.21v1.56c-.01 1.59-.84 2.39-2.47 2.39zm-2.7-5v-1.78c-.36.34-.73.57-1.13.7-.4.13-1.1.25-2.12.35-1.84.18-2.77.89-2.77 2.11 0 .64.22 1.14.67 1.51.45.37 1.05.55 1.81.55 1.09 0 1.94-.31 2.57-.92.63-.6.95-1.44.97-2.52z"></path>
                        <path d="M106.18 108.37v.69c.84-1.22 1.97-1.83 3.39-1.83 1.14 0 2.06.33 2.76.99.71.66 1.06 1.51 1.06 2.55 0 .74-.18 1.34-.54 1.8-.36.46-.83.69-1.41.69-.37 0-.69-.12-.95-.35a1.1 1.1 0 01-.39-.85c0-.27.1-.51.29-.72.19-.21.42-.31.68-.31.05 0 .15.02.32.08.1-.2.15-.38.15-.55 0-.49-.21-.9-.62-1.23-.41-.33-.93-.5-1.55-.5-1.27 0-2.11.59-2.53 1.76-.26.72-.39 1.81-.39 3.26v1.66c0 1.12.01 1.75.02 1.88.08.95.33 1.43.75 1.43.21 0 .43-.03.66-.09.18-.04.33-.06.46-.06.52 0 .78.26.78.79 0 .58-.33.87-1 .87h-.1c-.24 0-.61-.03-1.1-.09-.49-.06-.86-.09-1.1-.09-.65 0-1.16.02-1.53.05l-1.36.13c-.66 0-1-.29-1-.88 0-.52.31-.78.93-.78.16 0 .29.01.37.02l.37.08c.16.03.28.05.34.05.41 0 .63-.38.66-1.16v-1.28l-.07-5.7c-.05-.99-.29-1.48-.73-1.48-.13 0-.31.03-.54.08a2.8 2.8 0 01-.54.08c-.54 0-.8-.27-.8-.8 0-.59.35-.88 1.05-.88.16 0 .41.02.73.06.32.04.57.06.73.06.13 0 .34-.02.63-.06.13-.04.28-.06.46-.06.45-.03.66.21.66.69z"></path>
                        <path d="M122.36 107.22c1.61 0 2.81.64 3.61 1.93v-2.54c0-.67-.02-1.08-.05-1.23-.08-.52-.31-.78-.68-.78-.13 0-.31.03-.55.08-.24.05-.42.08-.55.08-.6 0-.9-.27-.9-.8 0-.55.41-.83 1.22-.83.13 0 .37.02.71.05.52.05.79.08.8.08.15 0 .36-.02.63-.06.28-.04.49-.06.63-.06.46 0 .68.34.68 1.01v.28c-.02.05-.03.5-.05 1.33 0 .1-.02.75-.07 1.94-.03.84-.05 1.48-.05 1.91v6.66c.02 1.11.07 1.79.15 2.06.08.27.28.4.6.4.21 0 .38-.02.5-.05.26-.03.45-.05.55-.05.61 0 .92.28.92.83 0 .57-.37.85-1.1.85-.16 0-.41-.02-.74-.06-.33-.04-.58-.06-.74-.06-.05 0-.24.02-.56.05-.2.05-.37.08-.51.08-.44 0-.66-.3-.66-.9v-.56c-.86 1.19-2.11 1.78-3.74 1.78-1.65 0-2.98-.62-3.98-1.86-1-1.24-1.51-2.86-1.51-4.87 0-2.03.5-3.65 1.49-4.86.97-1.22 2.29-1.83 3.95-1.83zm3.5 8.87c.07-.55.1-1.26.1-2.13 0-1.12-.04-1.88-.12-2.29-.15-.84-.5-1.49-1.05-1.96-.65-.55-1.41-.83-2.26-.83-1.15 0-2.04.44-2.69 1.33-.65.89-.97 2.1-.97 3.64 0 1.59.33 2.84 1.01 3.75.67.91 1.59 1.37 2.75 1.37.88 0 1.62-.26 2.21-.79.58-.52.93-1.22 1.02-2.09z"></path>
                        <path d="M139.08 107.22c1.08 0 1.99.29 2.72.88v-.1c0-.62.26-.93.78-.93.54 0 .8.27.8.8 0 .1-.01.35-.04.75-.05.33-.08.67-.08 1 0 .2.02.49.06.88.04.39.06.68.06.88 0 .64-.26.95-.79.95-.51 0-.78-.27-.79-.8v-.83c-.02-.5-.3-.93-.84-1.29s-1.18-.54-1.91-.54c-.75 0-1.37.2-1.88.59-.5.39-.75.88-.75 1.47 0 1.07.71 1.71 2.14 1.91l1.41.2c2.76.39 4.14 1.57 4.14 3.54 0 1.21-.45 2.18-1.34 2.93-.89.74-2.06 1.12-3.5 1.12-1.34 0-2.51-.39-3.5-1.18v.48c0 .6-.28.9-.83.9-.6 0-.9-.34-.9-1.03 0 .12.05-.32.15-1.31a5.795 5.795 0 00-.05-1.83c-.06-.35-.1-.63-.1-.83 0-.59.26-.88.78-.88.34 0 .56.08.65.25.09.17.14.61.16 1.33.02.72.38 1.31 1.1 1.76.71.45 1.62.68 2.73.68.8 0 1.45-.21 1.97-.63.52-.42.78-.95.78-1.58 0-1.14-.88-1.8-2.63-1.98-1.67-.18-2.95-.52-3.82-1.02-.88-.49-1.32-1.36-1.32-2.6 0-1.17.43-2.12 1.29-2.85.85-.72 1.97-1.09 3.35-1.09z"></path>
                    </g>
                    <g transform="matrix(.26458 0 0 .26458 -.057 5.271)">
                        <g>
                            <g>
                                <path d="M126.39 32.17v-9.6H9.22v9.6H0v26.46h9.22v36.81h107.94V76.99h18.07v-9.07H144V32.17zM36.06 85.99H18.44V50.16H9.68v-9.18l8.84.04v-8.84h17.54zm44.99-9.36h-9.24v9.35H54.18v-9.41h-9.01v-44.4h9.01v36.02h9.24V32.17h17.63zm54.14-18.03h-9.24v9.64h-18.03v17.74H90.24V32.17h36.15v8.75h8.81V58.6z"></path>
                                <path d="M107.93 41.03H117.51V59.08H107.93z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
